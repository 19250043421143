exports.components = {
  "component---src-components-templates-blogs-template-tsx": () => import("./../../../src/components/templates/BlogsTemplate.tsx" /* webpackChunkName: "component---src-components-templates-blogs-template-tsx" */),
  "component---src-components-templates-works-template-tsx": () => import("./../../../src/components/templates/WorksTemplate.tsx" /* webpackChunkName: "component---src-components-templates-works-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-works-index-tsx": () => import("./../../../src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */)
}

